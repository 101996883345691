// i18next-extract-mark-ns-start glossary

import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import PageTitle from 'src/components/typography/PageTitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import GlossarySection from 'src/components/glossary/GlossarySection'
import AudioPlayer from 'src/components/audio/AudioPlayer'

const IndexPage = ({
  pageContext: { title, slug, contentBlocks, canonicalUrl, audio },
}) => {
  const { t } = useTranslation('glossary')
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${t('Glossary')} - ${title}`}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <Layout>
        <ContentPageContainer className="min-h-screen">
          <PageTitle>{t('Glossary')}</PageTitle>
          <div className="mb-32">
            <GlossarySection
              title={title}
              slug={slug}
              contentBlocks={contentBlocks}
            />
            <AudioPlayer content={audio} className="w-64" />
          </div>
        </ContentPageContainer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
