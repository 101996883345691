import React, { FC } from 'react'
import {
  IGlossarySectionContentBlock,
  IHiglightedTextBlock,
  IHtmlBlock,
} from 'src/components/glossary/types'
import { StyledListWrapper } from 'src/components/typography/List'
import { CmsHtmlContentGrayUnderline } from 'src/components/typography/CmsHtmlContentFormats'
import HighlightedSubtitle from 'src/components/typography/HighlightedSubtitle'
import styled from 'styled-components'
import tw from 'twin.macro'

interface IGlossaryContentBlock {
  block: IGlossarySectionContentBlock
}

const StyledGlossaryContent = styled.div`
  &:last-child {
    ${tw`mb-0`}
  }
`

const GlossaryContentBlock: FC<IGlossaryContentBlock> = ({
  block,
}): JSX.Element => {
  switch (block.model.apiKey) {
    case 'news_html': {
      const htmlBlock = block as IHtmlBlock
      return (
        <StyledGlossaryContent className="mb-16">
          <StyledListWrapper className="text-left">
            <CmsHtmlContentGrayUnderline
              dangerouslySetInnerHTML={{ __html: htmlBlock.htmlContent }}
            />
          </StyledListWrapper>
        </StyledGlossaryContent>
      )
    }
    case 'highlighted_text': {
      const highlightedTextBlock = block as IHiglightedTextBlock
      return (
        <div className="-ml-2 md:-ml-6">
          <HighlightedSubtitle>{highlightedTextBlock.text}</HighlightedSubtitle>
        </div>
      )
    }
    default:
      return null
  }
}

export default GlossaryContentBlock
