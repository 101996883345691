import React, { FC } from 'react'
import Subtitle from 'src/components/typography/Subtitle'
import Article from 'src/components/typography/Article'
import styled from 'styled-components'
import 'src/components/laws/law-accordion-styles.css'
import { IGlossarySectionContentBlock } from 'src/components/glossary/types'
import GlossaryContentBlock from 'src/components/glossary/GlossaryContentBlock'

interface IGlossarySectionProps {
  title: string
  slug: string
  contentBlocks: IGlossarySectionContentBlock[]
}

export const DynamicHtmlStyles = styled.div``

const GlossarySection: FC<IGlossarySectionProps> = ({
  title,
  slug,
  contentBlocks,
}) => (
  <Article
    id={slug}
    noMarginMobile
    className="laws-accordion-section laws-accordion-section--open"
  >
    <Subtitle className="laws-accordion-title laws-accordion-title--open laws-accordion-title--last">
      <span className="flex lg:inline items-start leading-none py-0.5 lg:py-0">
        {title}
      </span>
    </Subtitle>
    {contentBlocks.map((contentBlock) => (
      <GlossaryContentBlock block={contentBlock} />
    ))}
  </Article>
)

export default GlossarySection
